import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Link } from 'gatsby';
import styled from 'styled-components';

const Styled404 = styled.div`
	margin-top: 2rem;
	padding-top: 5.3rem;
	.text-container {
		/* 		  nav 2+5.3  footer 13.5 */
		min-height: calc(100vh - 20.8rem);
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding: 3rem ${(p) => p.theme.gutter};
		h1 {
			color: ${(p) => p.theme.color.dark};
		}
		p {
			margin: 3rem;
		}
	}
	@media all and (min-width: 1600px) {
		margin-top: 2.5rem;
		padding-top: 6.8rem;
		.text-container {
			/* 		  nav 2.5+6.8  footer 13.5 */
			min-height: calc(100vh - 22.8rem);
			h1 {
				font-size: 3rem;
				line-height: 3.5rem;
			}
			p,
			a {
				font-size: 1.5rem;
				line-height: 1.8rem;
			}
		}
	}
	@media all and (max-width: 950px) {
		.text-container {
			padding: 5rem ${(p) => p.theme.gutter} 2rem;
		}
	}
	@media all and (max-width: 768px) {
		.text-container {
			/* 		  nav 2+5.3  footer 11.5 */
			min-height: calc(100vh - 18.8rem);
		}
	}
	@media all and (max-width: 668px) {
		.text-container {
			height: auto;
		}
	}
	@media all and (max-width: 500px) {
		.text-container {
			padding: 5rem ${(p) => p.theme.gutterMobile} 2rem;
		}
	}
`;

export default ({ data }) => {
	return (
		<Layout page="404">
			<SEO title="Page not found" />
			<Styled404>
				<div className="text-container">
					<h1>The page you are looking for is not found.</h1>
					<p>Looks like the content you're looking for is no longer here.</p>
					<Link to="/" className="ec-button">
						BACK TO HOMEPAGE
					</Link>
				</div>
			</Styled404>
		</Layout>
	);
};
